import React, { Component } from "react";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import _ from "lodash";
class Navbar extends Component {
  state = {
    count: 0
  };

  goToAdminPage = () => {
    const { count } = this.state;
    const { history, username } = this.props;

    if (count === 0) {
      setTimeout(() => {
        this.setState({
          count: 0
        });
      }, 5000);
    }
    if (count === 7 && username === "niceloop") {
      history.push("/admin");
      return;
    }

    this.setState({
      count: count + 1
    });
  };

  signOut = e => {
    e.preventDefault();
    Auth.signOut();
    window.location.href = "/";
  };
  // {hqInfo.name || ''}

  getBranchName = customerId => {
    const { branches } = this.props;
    if (customerId === "dashboard") {
      return "Dashboard";
    }
    if (customerId === "manageUser") {
      return "Manage User";
    }
    if (customerId === "liveScore") {
      return "Live Score";
    }
    if (customerId === "resetPassword") {
      return "Reset Password";
    }
    return branches[customerId].Value;
  };

  render() {
    const { setEnlarged, title, username, history } = this.props;
    return (
      <div className="topbar">
        <div className="topbar-left">
          {/* <div style={{ marginTop: 20 }}>
                    <span style={{ fontSize: 30, color: 'white' }}>Niceloop</span>
                </div> */}
          <a className="logo" onClick={this.goToAdminPage}>
            {/* <img src="assets/images/logo.png" alt="" height="20" className="logo-large" /> */}
            <span
              className="logo-large"
              style={{ fontSize: 30, color: "white" }}
            >
              HQ Report
            </span>
            <img
              src="assets/images/logo-sm.png"
              alt=""
              height="22"
              className="logo-sm"
            />
          </a>
        </div>

        <nav className="navbar-custom">
          <div className="search-wrap" id="search-wrap">
            <div className="search-bar">
              <input
                className="search-input"
                type="search"
                placeholder="Search"
              />
              <a
                href="#"
                className="close-search toggle-search"
                data-target="#search-wrap"
              >
                <i className="mdi mdi-close-circle" />
              </a>
            </div>
          </div>
          <ul className="navbar-right d-flex list-inline float-right mb-0">
            <li className="list-inline-item dropdown notification-list flags-dropdown d-none d-sm-inline-block">
              <a
                className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <i className="mdi mdi-settings" />
                {username || ""}
                <i className="mdi mdi-chevron-down" />
              </a>
              <div className="dropdown-menu dropdown-menu-animated">
                <a
                  href="#"
                  className="dropdown-item"
                  style={{ color: "red" }}
                  onClick={this.signOut}
                >
                  <i className="mdi mdi-logout" /> Sign Out
                </a>
                {/* <a href="#"  className="dropdown-item"><img src="assets/images/flags/germany_flag.jpg" alt=""  className="flag-img"/> Germany</a> */}
                {/* <a href="#"  className="dropdown-item"><img src="assets/images/flags/italy_flag.jpg" alt=""  className="flag-img"/> Italy</a> */}
                {/* <a href="#"  className="dropdown-item"><img src="assets/images/flags/spain_flag.jpg" alt=""  className="flag-img"/> Spain</a> */}
              </div>
            </li>
          </ul>

          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button
                className="button-menu-mobile open-left waves-effect"
                onClick={setEnlarged}
              >
                <i className="mdi mdi-menu" />
              </button>
            </li>
            {/* <li className="float-left">
                        <div className="align-self-center">
                            <span style={{ fontSize: 20 }}>Dashboard</span>
                        </div>
                    </li> */}
          </ul>
          <ul
            className="navbar-right d-flex list-inline float-left mb-0"
            style={{ width: "80%", overflow: "hidden", height: 60 }}
          >
            <li
              className="list-inline-item dropdown notification-list flags-dropdown  d-sm-inline-block"
              // d-none
            >
              <span
                id="shop-name"
                className="nav-link "
                style={{ fontSize: 20, color: "black" }}
              >
                {this.getBranchName(title)}
              </span>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hqInfo: state.HQ_Info,
    branches: state.branches,
    username: (state.user && state.user.loggedIn.username) || "unknown"
  };
};

export default connect(mapStateToProps)(Navbar);
