import { Auth } from 'aws-amplify';
import async from 'async'
import { fetchLoggedInUser } from '../actions/userActions'
 
const InitMiddleware = store => next => {
    return action => {
        if (action.type === "INIT") {

            //fetch
            Auth.currentAuthenticatedUser().then(function (user) {
                async.waterfall([
                    (cb) => {
                        store.dispatch(fetchLoggedInUser(user, cb))
                    },
                    // (cb) => {
                    //     store.dispatch(fetchUserList())
                    // },

                ])
            }).catch((err) => {
                // alert(err)
                console.log(err)
            })
        }
        next(action)
    }


}

export default InitMiddleware