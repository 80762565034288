import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from "history";
import { Route, Switch, Router } from "react-router-dom";
import Login from './view/Login'
// import ResetPassword from './view/ResetPassword'
import store from './shared/redux/store'
import { Provider } from "react-redux";
import { Auth } from 'aws-amplify';
import AdminPage from './view/AdminPage'

const hist = createBrowserHistory();
var pathName = window.location.pathname;

const main = () => {
    ReactDOM.render(
        <Provider store={store}>
            <Router history={hist}>
                <Switch>
                    <Route exact path={'/admin'} render={(props) => <AdminPage {...props} />} />
                    <Route exact path={'/'} render={(props) => <App {...props} />} />
                </Switch>
            </Router> 
        </Provider>
        ,
        document.getElementById("root")
    )
}
switch (pathName) {
    // case '/admin':
    //     ReactDOM.render(<AdminPage />, document.getElementById("root"));
    //     break
    // case '/resetPassword':
    //     ReactDOM.render(<ResetPassword />, document.getElementById("root"));
    //     break
    case '/':
        Auth.currentAuthenticatedUser().then(function (user) {
            main()
        }).catch((err) => {
            console.log(err)
            ReactDOM.render(<Login />, document.getElementById("root"));
        })
        break
    default:
        window.location.href = "/";
        break
}




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
// ReactDOM.render(<App />, document.getElementById('root'));
