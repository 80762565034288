import AWS from 'aws-sdk';
import swal from 'sweetalert'

const userPoolId = 'ap-southeast-1_ac1LQwEMu'
const API_KEY = 'AKIAJV7DLDUKL6BBXEEA'
const SECERT_KEY = '3B/yAPYf3d8JOexx9Aiaw8IAgAuE4f1GC6GkSeS/'

const documentClient = new AWS.DynamoDB.DocumentClient({
    region: 'ap-southeast-1',
    endpoint: 'dynamodb.ap-southeast-1.amazonaws.com',
    accessKeyId: API_KEY,
    secretAccessKey: SECERT_KEY
})
export const cognitoidentityserviceprovider =
    new AWS.CognitoIdentityServiceProvider(
        {
            apiVersion: '2016-04-18',
            region: 'ap-southeast-1',
            accessKeyId: API_KEY,
            secretAccessKey: SECERT_KEY
        });

export const deleteUser = (username, cb) => {
    var params = {
        UserPoolId: userPoolId, /* required */
        Username: username /* required */
    };
    cognitoidentityserviceprovider.adminDeleteUser(params, (err, data) => {
        if (err) {
            swal("เกิดข้อผิดพลาด", err.code, "error");
            console.log(err)
            cb && cb(err)
            return
        }
        cb && cb()
        swal("สำเร็จ!", `ลบ ${username} สำเร็จ`, "success");
    })
}

export default documentClient;