
import * as type from '../../actions/type'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case type.FETCH_BRANCHES:
            return action.payload
        default:
            return state
    }
}

export default reducer