import logger from "redux-logger";
import ReduxThunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import InitMiddleware from '../redux/middleware/init.middleware'
import reducers from './reducers/rootReducer'

const store = createStore(
    reducers,
    applyMiddleware(
        ReduxThunk,
        logger,
        InitMiddleware
    )
);

store.dispatch({
    type: "INIT"
})

export default store