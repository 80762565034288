import React from 'react';
import { fetchSalesProduct } from '../../shared/api/fetch'
import DateRangePickerModal from './DateRangePickerModal'
import moment from 'moment';
import _ from 'lodash'
import CatalogCard from './CatalogCard'
import ToppingCard from './ToppingCard'
import DeletedMenuCard from './DeletedMenuCard'
import CustomLoading from '../../shared/components/CustomLoading';
import MediaQuery from 'react-responsive';
import swal from 'sweetalert'

class ProductReport extends React.Component {
    state = {
        startDate: new Date(),
        endDate: new Date(),
        startDateString: moment().format('YYYY-MM-DD'),
        endDateString: moment().format('YYYY-MM-DD'),

        Catalogs: [],
        DeletedMenu: [],
        ToppingGroups: [],

        viewMode: 'catalog',
        loading: true
    }
    setViewMode = (mode) => {
        this.setState({
            viewMode: mode
        })
    }

    componentDidMount() {
        this.fetch()
    }
    componentWillReceiveProps(nextProps) {
        this.fetch(nextProps.match.params.customerId)
    }

    fetch = (forceCustomerId) => {
        const { customerId } = this.props
        const { startDateString, endDateString } = this.state
        this.setState({
            loading: true
        })
        let endDate = moment(endDateString).add(1, 'days').format('YYYY-MM-DD')

        let param = {
            "CustomerId": parseInt(forceCustomerId || customerId),     //  <==* use this,
            "From": `${startDateString}T07:00:00+07:00`,  //  <==* use this,
            "To": `${endDate}T07:00:00+07:00`,  //  <==* use this,
            // below fixed
            "BusinessMode": "1",
            "OrderBy": 2,
            "Shift": "All",
            "user": "All"
        }
        let self = this
        //props ส่งมาไม่ทันใช้ Force
        fetchSalesProduct(param)
            .then(res => {
                if (res) {

                    this.setState({
                        loading: false
                    })
                    self.setState({
                        Catalogs: res.data.Catalogs,
                        ToppingGroups: res.data.ToppingGroups,
                        DeletedMenu: res.data.DeletedMenu,
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
                alert(err)
            })


    }
    
    dateObjToString = (date) => {
        let month = date.getMonth() + 1
        if (month < 10) {
            month = '0' + month
        }
        const dateString = `${date.getFullYear().toString()}-${month.toString()}-${date.getDate() < 10 ? ('0' + date.getDate().toString()) : date.getDate().toString()}`
        return dateString
    }
    saveDates = (startDate, endDate) => {
        // const { startDate, endDate } = ranges.selection
        const startDateString = this.dateObjToString(startDate)
        const endDateString = this.dateObjToString(endDate)
        let diff = moment(endDateString).diff(moment(startDateString), 'days')
        diff++
        if (diff > 90) {
            swal("เกิดข้อผิดพลาด", 'กรุณาเลือกช่วงเวลาไม่เกิน 90 วัน', "error");
            return
        }

        this.setState({
            startDate,
            endDate,
            startDateString,
            endDateString,

        })

        setTimeout(() => {
            this.fetch()
        }, 500)
    }

    getDateInput = (mode) => {
        const { startDateString, endDateString, } = this.state
        return (
            <div className={`input-group bootstrap-touchspin bootstrap-touchspin-injected ${mode === 'mobile' ? '' : 'col-md-6'}`}
                // style={mode === 'mobile' ? { padding: 0 } : {}}
                onClick={() => window.$("#dateButton").click()
                } >
                <input type="text" value={`${moment(startDateString).format('DD/MM/YYYY')}  ถึงวันที่  ${moment(endDateString).format('DD/MM/YYYY')}`} name="demo1" className="form-control" disabled />
                <span className=" bootstrap-touchspin-postfix input-group-append">
                </span>
                <span className="input-group-btn input-group-append">
                    <button className="btn btn-info bootstrap-touchspin-up" style={{ height: 38 }} id="dateButton" type="button" data-toggle="modal" data-target="#myModal" ><i className="mdi mdi-calendar"></i></button>
                </span>
            </div >
        )
    }
    getButtonGroup = () => {
        const { viewMode } = this.state
        return (
            <div className="btn-group btn-group-toggle" >
                <label class={`btn btn-primary ${viewMode === 'catalog' ? 'active' : ''}`}>
                    <input type="radio" onClick={() => this.setViewMode('catalog')} /> Catalog
        </label>
                <label class={`btn btn-primary ${viewMode === 'topping' ? 'active' : ''}`}>
                    <input type="radio" onClick={() => this.setViewMode('topping')} /> Topping
        </label>
                <label class={`btn btn-primary ${viewMode === 'deleted' ? 'active' : ''}`}>
                    <input type="radio" onClick={() => this.setViewMode('deleted')} /> Deleted Menu
        </label>
            </div>
        )
    }
    render() {
        const { startDate, endDate, Catalogs, ToppingGroups, DeletedMenu, viewMode, loading } = this.state
        // const { loading } = this.props
        if (loading) {
            return <CustomLoading loading={loading} />
        }
        return (
            <div>
                <DateRangePickerModal saveDates={this.saveDates}
                    startDate={startDate} endDate={endDate}
                />
                <MediaQuery maxDeviceWidth={500}>
                    <div style={{ marginTop: 5 }}></div>
                    {this.getDateInput('mobile')}
                    <div style={{ marginTop: 15 }}></div>
                    <center>{this.getButtonGroup()}</center>

                </MediaQuery>
                <MediaQuery minDeviceWidth={501}>
                    <div className="row m-0">
                        <div className="col-md-10 offset-md-1">

                            <div className="card" style={{ marginTop: 10 }}>
                                <div className="card-body">
                                    <div className="row m-0">
                                        {this.getDateInput()}
                                        <div className="col-md-6 text-center" >
                                            {this.getButtonGroup()}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </MediaQuery>

                <div style={{ marginTop: 15 }}></div>
                <div className="row m-0">
                    <div className="col-12 col-xl-10 offset-xl-1 p-0">
                        {
                            viewMode === 'catalog' && <CatalogCard Catalogs={Catalogs} />
                        }
                        {
                            viewMode === 'topping' && <ToppingCard ToppingGroups={ToppingGroups} />
                        }
                        {
                            viewMode === 'deleted' && <DeletedMenuCard DeletedMenu={DeletedMenu} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}



export default (ProductReport)