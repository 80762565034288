import { fetchBranchesApi } from '../../api/fetch'
import * as type from './type'
import { setInitialLoading } from './loadingActions'
import _ from 'lodash'
export const fetchBranches = () => {

    return (dispatch, getState) => {
        const { HQ_Info } = getState()
        fetchBranchesApi(HQ_Info.branches || [])
            .then(res => {

                let branchesObj = {}

                _.map(res.data, data => {
                    branchesObj[data.CustomerId] = data
                })

                dispatch({
                    type: type.FETCH_BRANCHES,
                    payload: branchesObj
                    // payload: res.data || []
                    // payload: _.sortBy(res.data, i => i.Value.toUpperCase())
                });
                dispatch(setInitialLoading(false))

                // return res;
            })

    };
};