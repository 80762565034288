import React from 'react';
import { BeatLoader } from 'react-spinners';
import { css } from 'react-emotion';

const override = css`
    display: block; 
    margin: 0 auto;
    border-color: red;
`;
class CustomLoading extends React.Component {
    render() {
        const { loading } = this.props
        return (
            <div className='sweet-loading' style={{
                position: 'absolute',
                left: '55%',
                top: '45%',
                position: 'absolute',
                zIndex: 101,
                marginLeft: -16,
                marginRop: -16,
            }}>
                <BeatLoader
                    className={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={loading}
                />
            </div>
        );
    }
}

export default CustomLoading;