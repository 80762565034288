import React from 'react'
import _ from 'lodash'
import MediaQuery from 'react-responsive';

class DeletedMenuCard extends React.Component {

    render() {
        const { DeletedMenu } = this.props
        return (
            <div>
                <MediaQuery maxDeviceWidth={500}>
                    <ul class="list-group" style={{ paddingTop: 5, marginTop: 5 }}>

                        {
                            _.map(DeletedMenu, (menu, i) => {
                                // if (toppingGroup.Quantity === 0) return
                                return (
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        {menu.Name}
                                        <span style={{ color: '#e2595f' }}>฿{menu.Amount.toLocaleString()}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>

                </MediaQuery>
                <MediaQuery minDeviceWidth={501}>
                    <div className="card">
                        <div className="card-body">
                            {/* <h3 className="mt-0 header-title">DeletedMenu</h3> */}
                            <div className=" table-bordered" style={{ marginTop: 10 }}>
                                <table className="table mb-0 product-table">
                                    <thead className="thead-default">
                                        <tr>
                                            <th colSpan={2}>Name</th>
                                            <th>Amount</th>
                                            <th>Quantity</th>
                                            <th>Percent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(DeletedMenu, (menu, i) => {
                                                // if (toppingGroup.Quantity === 0) return
                                                return (

                                                    <tr >
                                                        <td>{i + 1}</td>
                                                        <td><span style={{ paddingLeft: 30 }}>{menu.Name}</span></td>
                                                        <td ><span style={{ fontWeight: 'bold' }}>{menu.Amount.toLocaleString()}</span></td>
                                                        <td ><span style={{ fontWeight: 'bold' }}>{menu.Quantity.toLocaleString()}</span></td>
                                                        <td ><span style={{ fontWeight: 'bold' }}>{menu.Percent.toLocaleString()}%</span></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}

export default DeletedMenuCard;