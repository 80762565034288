let firebase = require("firebase");
// require("firebase/firestore");

var config = {
    apiKey: "AIzaSyCTnEcbtpIuEV6WuSQiNiEisu92cyk7FlQ",
    authDomain: "backup-niceloop-v1.firebaseapp.com",
    databaseURL: "https://backup-niceloop-v1.firebaseio.com",
    projectId: "backup-niceloop-v1",
    storageBucket: "backup-niceloop-v1.appspot.com",
    messagingSenderId: "110209570441"
};
firebase.initializeApp(config);

export const rootRef = firebase.database().ref();
export const backupRef = rootRef.child('backup')
export const liveRef = backupRef.child('live')
export const customersRef = backupRef.child('customers')


