
import { combineReducers } from 'redux'
import user from './user'
import branches from './branches'
import HQ_Info from './HQ_Info'
import loading from './loadingReducer'

export default combineReducers({
    user,
    HQ_Info,
    branches,
    loading
})
