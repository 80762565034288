import React from 'react';
import documentClient from '../../shared/api/dynamoDb'
import AddUserModal from './AddUserModal'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import _ from 'lodash'
import { deleteUser } from '../../shared/api/dynamoDb'
import async from 'async'

class ManageUser extends React.Component {
    state = {
        username: '',
        password: '',
        users: []
    }

    componentDidMount() {
        this.fetchUser()
    }

    fetchUser = () => {
        const { loggedInUser } = this.props
        let params = {
            IndexName: 'customerId-index',
            TableName: "V1_HQ_Users",
            KeyConditionExpression: 'customerId = :customerId',
            ExpressionAttributeValues: {
                ":customerId": loggedInUser.customerId || '00',
            }
        };

        const self = this

        documentClient.query(params, function (err, data) {
            if (err) {
                swal("เกิดข้อผิดพลาด", err, "error");
            } else {
                self.setState({
                    users: data.Items
                })
            }
        })
    }

    removeUser = (user) => {
        // deleteUser
        async.waterfall([

            (cb) => {
                let params = {
                    TableName: "V1_HQ_Users",
                    Key: {
                        username: user.username,
                        // customerId: user.customerId
                    },
                };
                documentClient.delete(params, function (err, data) {
                    if (err) {
                        cb(err)
                    } else {
                        cb()
                    }
                });
            },
            (cb) => {
                deleteUser(user.username, cb)
            },

        ], (err, result) => {
            if (err) {
                swal("เกิดข้อผิดพลาด", err, "error");
                return
            }
            this.fetchUser()
            // fetch again
        })
    }



    render() {
        const { users } = this.state
        return (
            <div className="page-title-box" >
                <AddUserModal fetchUser={this.fetchUser} />
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="card card-body">
                            <div className="row justify-content-between m-0">
                                <div><h3 className="card-title font-20 mt-0">Manage User</h3></div>
                                <div><button data-toggle="modal" data-target="#myModal"
                                    type="button" class="btn btn-success waves-effect waves-light">
                                    <i className="mdi mdi-account-plus"></i> Create</button></div>
                            </div>
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead>

                                        <tr>
                                            <th>Username</th>
                                            <th>Role</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(users, user => {
                                                return (
                                                    <tr>
                                                        <th scope="row">{user.username}</th>
                                                        <th scope="row">{user.role.toUpperCase()}</th>
                                                        <td>{user.role !== 'admin' &&
                                                            <a href="#" style={{ color: 'red', fontSize: 20 }}
                                                                onClick={e => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    this.removeUser(user)
                                                                }}
                                                            >
                                                                <i className="mdi mdi-account-remove"></i></a>}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.user.loggedIn,
    }
}

// function mapDispatchToProps(dispatch) {
//     let actions = bindActionCreators({ ...hqInfoActions }, dispatch)
//     return {
//         actions,
//     }
// }

export default connect(mapStateToProps)(ManageUser)