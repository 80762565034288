
import * as type from './type'
import documentClient from '../../api/dynamoDb'
import { fetchHQ } from './hqInfoActions'
import { Auth } from 'aws-amplify';

export const fetchLoggedInUser = (user, cb) => {
    return (dispatch, getState) => {
        let param = {
            TableName: "V1_HQ_Users",
            Key: {
                username: user.username,
            },
        };
        fetchGet(param, data => {
            dispatch({
                type: type.FETCH_LOGGEDIN_USER,
                payload: (data && data.Item) || {}
            });
            try {
                dispatch(fetchHQ(data.Item.customerId, cb))
            }
            catch (err) {
                Auth.signOut();
                window.location.href = '/'
            }
        });
    };
};

const fetchGet = (params, cb) => {
    documentClient.get(params, (err, data) => { 
        if (err) {
            console.log(err);
            // alert(err)
        } else {
            cb(data);
        }
    });
};