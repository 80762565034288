import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import swal from "sweetalert";
import * as firebaseRef from "../../shared/firebaseApi/firebaseRef";
// import TextTransition from "react-text-transition";

class LiveScore extends React.Component {
  state = {};

  componentDidMount() {
    const { hqInfo } = this.props;

    _.map(hqInfo.branches, customerId => {
      firebaseRef.liveRef.child(customerId).on("value", snapshot => {
        if (!snapshot) return;
        let data = snapshot.val();
        this.setState({
          [customerId]: data
        });
      });
    });
  }

  computePaidAndPending = () => {
    let totalPaid = 0;
    let totalPending = 0;
    _.forEach(this.state, data => {
      if (!data) return;
      totalPending += data.pending || 0;
      totalPaid += (data.payment && data.payment.amount) || 0;
    });

    return { totalPaid, totalPending };
  };

  componentWillUnmount() {
    const { hqInfo } = this.props;

    _.map(hqInfo.branches, customerId => {
      firebaseRef.liveRef.child(customerId).off();
    });
  }

  render() {
    const { hqInfo, branches, match, history } = this.props;
    let index = 0;
    let total = this.computePaidAndPending();
    return (
      <div>
        <div className="row justify-content-between m-0">
          <div>
            <span style={{ fontWeight: "bold" }}>
              All Payment:{" "}
              <span style={{ color: "blue" }}>
                ฿ {total.totalPaid.toLocaleString()}
              </span>
            </span>{" "}
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>
              Pending:{" "}
              <span style={{ color: "red" }}>
                ฿ {total.totalPending.toLocaleString()}
              </span>
            </span>{" "}
          </div>
        </div>
        <div style={{ marginTop: 10 }} />
        <ul class="list-group">
          {_.reduce(
            hqInfo.branches,
            (acc, customerId) => {
              var data = this.state[customerId] || null;

              if (data == null) {
                return acc;
              }
              let lastUpdate = data.pendingLastUpdate;
              let pending = data.pending || 0;
              let pendingTables = data.pendingTables || 0;
              let avgTables = pending / pendingTables;

              let paid = (data.payment && data.payment.amount) || 0;
              let bills = (data.payment && data.payment.counter) || 0;
              let avgBills = paid / bills;
              acc.push(
                <li
                  class="list-group-item"
                  // onClick={() => history.push(`${match.url}/liveTable/${customerId}`)}
                  onClick={() =>
                    history.push(`/liveTable/${customerId}`)
                  }
                  style={{
                    backgroundColor: index % 2 === 0 ? "#e6ecf7" : "white",
                    padding: 10
                  }}
                >
                  <div class=" d-flex justify-content-between align-items-center">
                    <span>{branches[customerId].Value}</span>
                    <small style={{ color: "grey" }}>
                      Last Update: {moment(lastUpdate).format("HH:mm ddd")}
                    </small>
                  </div>
                  <LiveScoreRow
                    col1={"Paid"}
                    col2={`฿ ${paid.toLocaleString()}`}
                    col3={`${bills.toLocaleString()} bills`}
                    col4={`avg. ${parseInt(
                      avgBills.toFixed(0)
                    ).toLocaleString()}/bills`}
                    mode={"paid"}
                  />
                  <LiveScoreRow
                    col1={"Pending"}
                    col2={`฿ ${pending.toLocaleString()}`}
                    col3={`${pendingTables.toLocaleString()} tables`}
                    col4={`avg. ${parseInt(
                      avgTables.toFixed(0)
                    ).toLocaleString()}/table`}
                    mode={"pending"}
                  />
                </li>
              );
              index++;
              return acc;
            },
            []
          )}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hqInfo: state.HQ_Info,
    branches: state.branches
  };
};

export default connect(mapStateToProps)(LiveScore);

const LiveScoreRow = ({ col1, col2, col3, col4, mode }) => {
  return (
    <div className="row m-0">
      <div className="col-2" style={{ paddingLeft: 0 }}>
        <small style={{ color: "grey" }}>{col1}</small>
      </div>
      <div className="col-3">
        {/* <TextTransition
                    text={<small style={{ fontWeight: 'bold', color: mode === 'paid' ? 'blue' : 'red' }}>{col2}</small>}
                /> */}
        <small
          style={{
            fontWeight: "bold",
            color: mode === "paid" ? "blue" : "red"
          }}
        >
          {col2}
        </small>
      </div>
      <div className="col-3">
        <small style={{ color: "grey" }}>{col3}</small>
      </div>
      <div className="col-4" style={{ paddingRight: 0 }}>
        <small style={{ color: "grey" }}>{col4}</small>
      </div>
    </div>
  );
};
