import React from 'react'
import swal from 'sweetalert';
import { signUp, logIn } from '../../shared/api/authen'
import MediaQuery from 'react-responsive';

class Login extends React.Component {
    state = {
        username: '',
        password: '',
        count: 0
    }


    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    signUpAccount = () => {
        const { username, password } = this.state
        signUp({ username, password })
    }

    loginAccount = () => {
        const { username, password } = this.state

        if (username === '' || password === '') {
            swal("เกิดข้อผิดพลาด", 'กรุณากรอกข้อมูลให้ครบถ้วน', "error");
            return
        }

        logIn(username, password)


        //login

    }
    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.loginAccount()
        }
    }

    render() {
        return (
            <div className="fixed-left" style={{ overflow: 'visible' }}>
                <div id="preloader" >
                    <div id="status" >
                        <div className="spinner"></div>
                    </div>
                </div>
                <div className="accountbg"></div>
                {/* <div className="home-btn d-none d-sm-block">
                    <a href="index.html" className="text-white"><i className="mdi mdi-home h1"></i></a>
                </div> */}
                <div className="wrapper-page">
                    {/* <div className="row ">
                        <div className="col-10 offset-1"> */}
                    <div>
                        <div>

                            <div className="card" style={{ marginLeft: 10, marginRight: 10 }}>
                                <div className="card-body">
                                    <MediaQuery maxDeviceWidth={500}>
                                        <center><h4 >Niceloop HQ Report</h4></center>
                                        <center><p className="text-muted">Sign in to continue..</p></center>
                                    </MediaQuery>
                                    <MediaQuery minDeviceWidth={501}>
                                        <div className="p-3">
                                            <div className="float-right text-right">
                                                <h4 className="font-18 mt-3 m-b-5">HQ Report</h4>
                                                <p className="text-muted">Sign in to continue..</p>
                                            </div>
                                            <a className="logo-admin" style={{ fontSize: 35, fontWeight: 'bold' }}>
                                                Niceloop
                                </a>
                                        </div>
                                    </MediaQuery>

                                    <div className="p-3">


                                        <div className="form-group">
                                            <label for="username">Username</label>
                                            <input type="text" className="form-control" name="username" placeholder="Enter username"
                                                onKeyPress={this._handleKeyPress}
                                                onChange={this.handleOnChange} />

                                        </div>

                                        <div className="form-group">
                                            <label for="userpassword">Password</label>
                                            <input type="password" className="form-control" name="password" placeholder="Enter password"
                                                onChange={this.handleOnChange}
                                                onKeyPress={this._handleKeyPress}
                                            />
                                        </div>

                                        <div className="form-group row m-t-30">
                                            {/* <div className="col-sm-6">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                <label className="custom-control-label" for="customControlInline">Remember me</label>
                                            </div>
                                        </div> */}
                                            <div className="col-sm-12 ">
                                                <button style={{ width: '100%' }} onClick={this.loginAccount} className="btn btn-primary w-md waves-effect waves-light" >Log In</button>
                                            </div>
                                        </div>
                                        {/* <div >
                                    <button style={{ width: '100%' }} onClick={this.signUpAccount} className="btn btn-primary w-md waves-effect waves-light" >Sign Up</button>
                                </div> */}

                                        <div className="form-group m-t-30 mb-0 row">
                                            <div className="col-12 text-center">
                                                {/* <a href="pages-recoverpw.html" className="text-muted"><i className="mdi mdi-lock"></i> Forgot your password?</a> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;