import React from 'react'
import { cognitoidentityserviceprovider } from '../../shared/api/dynamoDb'
import { Auth } from 'aws-amplify';
import swal from 'sweetalert'
class ResetPasswordForm extends React.Component {
    state = {
        username: 'shabuku01',
        oldPassword: '',
        newPassword: '',
        reNewPassword: ''
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    signOut = () => {
        Auth.signOut();
        window.location.href = '/'
    }
    submit = () => {
        const { oldPassword, newPassword, reNewPassword } = this.state
        if (oldPassword === '' || newPassword === '' || reNewPassword === '') {
            swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
            return
        }
        if (newPassword !== reNewPassword) {
            swal("เกิดข้อผิดพลาด", "กรุณากรอก New Password ให้ตรงกันทั้งสองช่อง", "error");
            return
        }
        let self = this
        Auth.currentAuthenticatedUser().then(function (user) {
            let param = {
                "AccessToken": user.signInUserSession.accessToken.jwtToken,
                "PreviousPassword": oldPassword,
                "ProposedPassword": newPassword
            }
            cognitoidentityserviceprovider.changePassword(param,
                (err, res) => {
                    if (err) {
                        swal("เกิดข้อผิดพลาด", err.message, "error");
                        return
                    }
                    if (res) {
                        swal("สำเร็จ !", "เปลี่ยน Password สำเร็จ", "success")
                            .then(() => {
                                self.signOut()
                            })
                        return
                    }
                })
        }).catch((err) => {
            alert(err)
        })
    }

    render() {
        const { oldPassword, newPassword, reNewPassword } = this.state
        const { loggedInUser } = this.props
        return (
            <div className="page-title-box">
                {/* <div className="accountbg"></div> */}
                <div className="wrapper-page">
                    <div className="card">
                        <div className="card-body">
                            <div className="p-3">
                                <div className="float-center text-center">
                                    <h3 className="mt-4 m-b-5">Reset Password</h3>
                                </div>
                            </div>

                            <div className="p-3">
                                <div className="form-group">
                                    <label >Username</label>
                                    <input className="form-control" value={loggedInUser.username} disabled />
                                </div>
                                <div className="form-group">
                                    <label >Old Password</label>
                                    <input className="form-control" type="password" name="oldPassword" value={oldPassword} onChange={this.handleOnChange} />
                                </div>
                                <div className="form-group">
                                    <label >New Password</label>
                                    <input className="form-control" type="password" name="newPassword" value={newPassword} onChange={this.handleOnChange} />
                                </div>
                                <div className="form-group">
                                    <label >Re-type New Password</label>
                                    <input className="form-control" type="password" name="reNewPassword" value={reNewPassword} onChange={this.handleOnChange} />
                                </div>

                                <div className="form-group row m-t-20">
                                    <div className="col-12 text-right">
                                        <button className="btn btn-primary w-md waves-effect waves-light" onClick={this.submit}>Reset</button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </div>);
    }
}

export default ResetPasswordForm;