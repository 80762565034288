import * as type from './type'

export const setInitialLoading = (bool) => {

    return (dispatch, getState) => {
        dispatch({
            type: type.SET_INITIAL_LOADING,
            payload: bool
        })
    };
};

export const setCustomLoading = (bool) => {

    return (dispatch, getState) => {
        dispatch({
            type: type.SET_CUSTOM_LOADING,
            payload: bool
        })
    };
};
