import React from 'react';
import documentClient from '../../shared/api/dynamoDb'
import _ from 'lodash'
import { fetchBranchesApi } from '../../shared/api/fetch'

class EditUser extends React.Component {
    state = {
        shopName: '',
        shopUid: '',
        username: '',
        shopName: '',
        branches: [],
        txtBranch: '',
        loading: true,
    }
    render() {
        const { branches, txtBranch, shopName, username, loading, uid } = this.state

        return (
            <div>
                <div className="row" style={{ margin: 0 }}>
                    <DivColMd3>
                        <small style={{ color: 'grey' }}>Username</small>
                        <input className="form-control" type="text" placeholder="Ex.:niceloop"
                            value={username} name="username" onChange={this.handleOnChange} />
                    </DivColMd3>
                    <div className="col-md-3 align-self-end">
                        <button onClick={this.findUser} type="button" className="btn btn-success waves-effect waves-light" >Find</button>
                    </div>
                </div>
                {
                    loading !== true &&
                    <div>
                        <div className="row" style={{ margin: 0 }}>
                            <DivColMd3>
                                <small style={{ color: 'grey' }}>Shop Name</small>
                                <input className="form-control" type="text" placeholder="Ex.:niceloop"
                                    value={shopName} name="shopName" onChange={this.handleOnChange} />
                            </DivColMd3>
                            <div className="col-md-2 align-self-end">
                                <small style={{ color: 'grey' }}>Uid</small>
                                <input className="form-control" type="text"
                                    value={uid} name="uid" disabled />
                            </div>
                        </div>
                        <div className="row" style={{ margin: 0, padding: 0 }}>
                            <DivColMd3>
                                <small style={{ color: 'grey' }}>เพิ่มสาขา</small>
                                <input className="form-control" type="text" placeholder="Ex.:3180" value={txtBranch} name="txtBranch" onChange={this.handleOnChange} />
                            </DivColMd3>
                            <div className="col-md-3 align-self-end">
                                <button onClick={this.addBranch} type="button" className="btn btn-success waves-effect waves-light" >Add</button>
                            </div>
                        </div>
                        <div className="row" style={{ margin: 0, marginTop: 15 }}>
                            {
                                _.map(branches, (branch, index) => {
                                    return (
                                        <div className="col-md-2">
                                            <div className=" card" style={{ backgroundColor: 'lightgrey' }}>
                                                <div style={{ margin: 10 }}>
                                                    <div style={{ margin: 0 }}>
                                                        <div className="row justify-content-between" style={{ margin: 0 }}>
                                                            <div>{branch.CustomerId}</div>
                                                            <div><a href="#"
                                                                onClick={e => {
                                                                    e.preventDefault()
                                                                    this.deleteBranch(index)
                                                                }}
                                                                style={{ color: 'red' }}><i className="dripicons-trash"></i></a></div>
                                                        </div>
                                                        <div> {branch.Value}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <button
                            disabled={this.validate()}
                            onClick={this.editShop} type="button" className="btn btn-warning waves-effect waves-light">SAVE</button>

                    </div>
                }
            </div>
        );
    }

    editShop = () => {
        const { uid, shopName, branches } = this.state
        let branchesArray = []
        _.map(branches, i => {
            branchesArray.push(i.CustomerId)
        })
        let params = {
            TableName: "V1_HQ_HQ",
            Key: {
                uid: uid
            },
            UpdateExpression: "set #name = :newShopName, branches = :newBranches",
            ExpressionAttributeNames: { "#name": "name" },
            ExpressionAttributeValues: {
                ":newShopName": shopName,
                ":newBranches": branchesArray
            }
        };
        documentClient.update(params, function (err, data) {
            if (err) {
                alert(err);
            } else {
                alert('แก้ไขสำเร็จ')
            }
        });


    }

    findUser = () => {
        const { username } = this.state
        let param1 = {
            TableName: 'V1_HQ_Users',
            Key: {
                username: username,
            }
        }
        fetch(param1, res => { //FETCH USER
            if (_.isEmpty(res)) {
                alert('ไม่มี Username นี้')
                this.setState({ loading: true })
                return
            }
            let param2 = {
                TableName: "V1_HQ_HQ",
                Key: {
                    uid: res.Item.customerId,
                },
            }
            fetch(param2, res2 => { //FETCH USER
                fetchBranchesApi([res2.Item.branches])
                    .then(res3 => {
                        this.setState({
                            branches: res3.data,
                            shopName: res2.Item.name,
                            uid: res2.Item.uid,
                            loading: false,
                            txtBranch: ''
                        })
                    })

            })

        })
    }
    validate = () => {
        const { username, password, shopName, branches } = this.state
        if (shopName === '' || branches.length < 1) {
            return true
        }
        return false
    }
    deleteBranch = (index) => {
        const { branches } = this.state
        let tmpBranch = [...branches.slice(0, index), ...branches.slice(index + 1)]
        this.setState({
            branches: tmpBranch
        })
    }
    addBranch = (e) => {
        const { txtBranch } = this.state
        if (txtBranch === '') return
        let self = this
        fetchBranchesApi([txtBranch])
            .then(res => {
                if (res === undefined || res.data.length < 1) {
                    alert('ไม่มีร้านหมายเลขนี้')
                    return
                }
                let branches = _.cloneDeep(this.state.branches)
                branches.push({ CustomerId: txtBranch, Value: res.data[0].Value })
                self.setState({
                    branches,
                    txtBranch: ''
                })
            })
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

}

export default EditUser;

const fetch = (params, cb) => {

    documentClient.get(params, (err, data) => {
        if (err) {
            console.log(err);
            // alert(err)
        } else {
            cb(data);
        }
    });
}

const DivColMd3 = ({ children }) => {
    return (
        <div className="col-md-3" style={{ padding: 0 }}>
            {children}
        </div>
    )

}