import React from 'react';
import CreateUserForm from './CreateUser'
import EditUser from './EditUser'
import user from '../../shared/redux/reducers/user';


class AdminPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: true
        };
    }

    componentDidMount() {
        // if (window.prompt('กรอกรหัสผ่าน') !== 'nice4321') {
        //     window.location.href = '/'
        // } else {
        //     this.setState({
        //         status: true
        //     })
        // }
    }

    render() {
        const { status } = this.state
        if (status === false) {
            return <div></div>
        }
        return (
            <div style={{ margin: 15 }}>
                <div className="card">
                    <div className="card-body">

                        <h4 className="mt-0 header-title">Admin Page</h4>
                        <p className="text-muted m-b-30 font-14">รหัสผ่าน: nice4321</p>

                        <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#home1" role="tab">
                                    <span className="d-none d-md-block">Create User</span><span className="d-block d-md-none"><i className="mdi mdi-home-variant h5"></i></span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#profile1" role="tab">
                                    <span className="d-none d-md-block">Edit User</span><span className="d-block d-md-none"><i className="mdi mdi-account h5"></i></span>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane active p-3" id="home1" role="tabpanel">
                                <CreateUserForm />
                            </div>
                            <div className="tab-pane p-3" id="profile1" role="tabpanel">
                                <EditUser />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default AdminPage;