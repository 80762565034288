import React from "react";

const SummaryCard = ({ title, subtitle, marginLeft, marginTop }) => {
  return (
    <div
      className="align-self-end"
      style={{ flexGrow: 1, marginTop: marginTop || 0 }}
    >
      <div
        className="card"
        style={{ minHeight: 100, marginLeft: marginLeft || 5, marginBottom: 0 }}
      >
        <div className="card-body">
          <center>
            <span style={{ fontSize: 20, color: "#e2595f" }}>{title}</span>
          </center>
          <center>
            <span>{subtitle}</span>
          </center>
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
