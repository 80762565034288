import React from 'react'
import SalesSummary from './SalesSummary'
import ProductSummary from './ProductSummary'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'

class Sales extends React.Component {
    state = {
        customerId: ''
    }

    componentDidMount() {
        const { match } = this.props
        this.setState({
            customerId: match.params.customerId
        })
    }

    componentWillReceiveProps(nextProps) {
        const { match } = nextProps
        this.setState({
            customerId: match.params.customerId
        })
    }

    render() {
        const { match, loading } = this.props
        const { customerId } = this.state

        if (customerId === '') {
            return <h1>Loading...</h1>
        }

        return (
            <div className="page-title-box" >
                <ul className="nav nav-pills nav-bg-custom " role="tablist">
                    <li className="nav-item waves-effect waves-light">
                        <a className="nav-link active show" data-toggle="tab" href="#home-1" role="tab" aria-selected="false">
                            <span className=" d-md-block">ยอดขาย</span>
                            {/* <span className="d-block d-md-none"><i className="mdi mdi-star h5"></i></span> */}
                        </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                        <a className="nav-link " data-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                            <span className=" d-md-block">สินค้า</span>
                            {/* <span className="d-block d-md-none"><i className="mdi mdi-silverware-variant h5"></i></span> */}
                        </a>
                    </li>
                </ul>


                <div className="tab-content">
                    <div className="tab-pane active show" id="home-1" role="tabpanel" style={{ padding: 0, paddingTop: 16 }}>
                        <SalesSummary match={match} customerId={customerId} />
                    </div>
                    <div className="tab-pane" id="profile-1" role="tabpanel" style={{ padding: 0, paddingTop: 16 }}>
                        <ProductSummary match={match} customerId={customerId} />
                    </div>
                </div>



            </div>
        )
    }
}


export default withRouter(Sales)