import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Container from './layout/Container'

class App extends Component {

  render() {
    const { history } = this.props
    return (
      // <Provider store={this.props.store}>
      <Router>
        <Container history={history} />
      </Router>
      // </Provider>
    );
  }
}


export default App