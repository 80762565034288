
import axios from 'axios'

const salesDataUrl = 'https://bd8gco41te.execute-api.ap-southeast-1.amazonaws.com/dev/report/shopNameList'
const productUrl = 'https://app.niceloop.com/api/ReportOrder/SumOrderItemsWithCustomerId'

export const fetchBranchesApi = (data) => {
    return axios
        .post(salesDataUrl, data)
        .catch(err => {
            console.log(err)
        })

}

export const fetchSalesProduct = (data) => {
    return axios
        .post(productUrl, data)
        .catch(err => {
            console.log(err)
        })
}