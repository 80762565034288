import React from 'react'
import _ from 'lodash'
import MediaQuery from 'react-responsive';

class ToppingCard extends React.Component {

    render() {
        const { ToppingGroups } = this.props
        return (
            <div>
                <MediaQuery maxDeviceWidth={500}>
                    {
                        _.map(ToppingGroups, toppingGroup => {
                            if (toppingGroup.Quantity === 0) return
                            return (
                                <ul class="list-group" style={{ paddingTop: 5, marginTop: 5 }}>
                                    <li class="list-group-item d-flex justify-content-between align-items-center"
                                        style={{ backgroundColor: '#e6ecf7' }}
                                    >
                                        {toppingGroup.Name}
                                        <span style={{ color: '#e2595f' }}>Qty:{toppingGroup.Quantity}</span>
                                    </li>
                                    {_.map(toppingGroup.Toppings, item => {
                                        if (item.Quantity === 0) return
                                        return (
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                {item.Name}
                                                <span >Qty:{item.Quantity.toLocaleString()}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            )
                        })

                    }

                </MediaQuery>
                <MediaQuery minDeviceWidth={501}>
                    <div className="card">
                        <div className="card-body">
                            {/* <h3 className="mt-0 header-title">ToppingGroups</h3> */}
                            {
                                _.map(ToppingGroups, toppingGroup => {
                                    if (toppingGroup.Quantity === 0) return
                                    let itemIndex = 0

                                    return (
                                        <div className=" table-bordered" style={{ marginTop: 10 }}>
                                            <table className="table mb-0 product-table">
                                                <thead className="thead-default">
                                                    <tr>
                                                        <th colSpan={2}>{toppingGroup.Name}</th>
                                                        <th>Quantity<br />{toppingGroup.Quantity}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {_.map(toppingGroup.Toppings, item => {
                                                        if (item.Quantity === 0) return
                                                        itemIndex++

                                                        return (
                                                            <tr >
                                                                <td style={{ width: '5%' }}>{itemIndex}.</td>
                                                                <td><span >{item.Name}</span></td>
                                                                <td ><span style={{ fontWeight: 'bold' }}>{item.Quantity.toLocaleString()}</span></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}

export default ToppingCard;