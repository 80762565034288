import React from 'react'
import _ from 'lodash'
import MediaQuery from 'react-responsive';

class CatalogCard extends React.Component {

    render() {
        const { Catalogs } = this.props

        return (
            <div>
                <MediaQuery maxDeviceWidth={500}>

                    {
                        _.map(Catalogs, catalog => {
                            if (catalog.Quantity === 0) return
                            return (
                                <div id={`accordion${catalog.Id}`}>
                                    <div className="card" style={{ marginBottom: 5 }}>
                                        <div className="card-header" id="headingOne" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 6, paddingBottom: 6 }}>
                                            <div className="row justify-content-between m-0">
                                                <ExpanTitleButton id={`item${catalog.Id}`} title={<span>{catalog.Name}</span>} />
                                                <ExpanTitleButton id={`item${catalog.Id}`}
                                                    title={
                                                        <span style={{ color: '#e2595f' }}><span style={{ fontWeight: 'light', color: 'grey' }}>{catalog.Percent}%</span> ฿{catalog.Amount.toLocaleString()}</span>
                                                    } />
                                            </div>
                                        </div>

                                        <div id={`item${catalog.Id}`} className="collapse " aria-labelledby="headingOne" data-parent={`#accordion${catalog.Id}`}>
                                            <div className="card-body" style={{ padding: 10 }}>
                                                <ul class="list-group" >

                                                    {_.map(catalog.Items, (item) => {
                                                        if (item.Quantity === 0) return
                                                        let res = (
                                                            <li class="list-group-item ">
                                                                <div class=" d-flex justify-content-between align-items-center" >
                                                                    <span style={{ color: 'blue', fontWeight: 'bold' }} >{item.Quantity.toLocaleString()} <span style={{ color: 'black' }}>{item.Name}</span></span>
                                                                    <span style={{ color: '#e2595f' }}>฿{item.Amount.toLocaleString()}</span>
                                                                </div>
                                                                {
                                                                    _.map(item.SubItems, subItem => {
                                                                        if (subItem.Quantity === 0) return
                                                                        const subItemLength = _.size(item.SubItems)
                                                                        if (subItem.Name === 'Regular' && subItemLength <= 1) return

                                                                        return (
                                                                            <div class=" d-flex justify-content-between align-items-center"
                                                                                style={{ borderBottomStyle: 'dotted', borderBottomWidth: 1, marginLeft: 10, marginRight: 10 }}
                                                                            >
                                                                                <span style={{ color: 'grey', fontSize: 13 }} >{subItem.Quantity.toLocaleString()} <span style={{ color: 'black' }}>{subItem.Name}</span></span>
                                                                                <span style={{ color: '#e2595f', fontSize: 13 }}>฿{subItem.Amount.toLocaleString()}</span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </li>
                                                        )
                                                        return res
                                                    })}

                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                </MediaQuery>
                <MediaQuery minDeviceWidth={501}>
                    <div className="card">
                        <div className="card-body">
                            {/* <h3 className="mt-0 header-title">Catalogs</h3> */}

                            {
                                _.map(Catalogs, catalog => {
                                    if (catalog.Quantity === 0) return
                                    let itemIndex = 0

                                    return (
                                        <div className=" table-bordered" style={{ marginBottom: 20 }}>
                                            <table className="table mb-0 product-table" >
                                                <thead className="thead-default">
                                                    <tr>
                                                        <th colSpan={2} style={{ width: '50%' }}>{catalog.Name}</th>
                                                        <th>Quantity<br />{catalog.Quantity.toLocaleString()}</th>
                                                        <th>Amount<br />{catalog.Amount.toLocaleString()}</th>
                                                        <th>Percent<br />{catalog.Percent.toLocaleString()}%</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {_.map(catalog.Items, (item) => {
                                                        if (item.Quantity === 0) return
                                                        itemIndex++
                                                        let res = (
                                                            [<tr >
                                                                <td style={{ width: '5%' }}>{itemIndex}.</td>
                                                                <td><span >{item.Name}</span></td>
                                                                <td ><span style={{ fontWeight: 'bold' }}>{item.Quantity.toLocaleString()}</span></td>
                                                                <td ><span style={{ fontWeight: 'bold' }}>{item.Amount.toLocaleString()}</span></td>
                                                                <td ><span style={{ fontWeight: 'light' }}>{item.Percent}%</span></td>
                                                            </tr>,
                                                            _.map(item.SubItems, subItem => {
                                                                if (subItem.Quantity === 0) return
                                                                const subItemLength = _.size(item.SubItems)
                                                                if (subItem.Name === 'Regular' && subItemLength <= 1) return

                                                                return (
                                                                    <tr >
                                                                        <td></td>
                                                                        <td ><span style={{ paddingLeft: 30 }}>- {subItem.Name}</span></td>
                                                                        <td style={{ textAlign: 'right' }}><span>{subItem.Quantity.toLocaleString()}</span></td>
                                                                        <td style={{ textAlign: 'right' }}><span>{subItem.Amount.toLocaleString()}</span></td>
                                                                        {/* <td style={{ textAlign: 'right' }}><span>{subItem.Percent}%</span></td> */}
                                                                        <td></td>
                                                                    </tr>
                                                                )
                                                            })
                                                            ]
                                                        )
                                                        return res
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}



export default CatalogCard;

const ExpanTitleButton = ({ id, title, disabled }) => {
    return (
        <button disabled={disabled} class="btn btn-link" data-toggle="collapse" data-target={`#${id}`} aria-expanded="true" aria-controls={`#${id}`}>
            {title}
        </button>

    )
}