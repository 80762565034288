
import * as type from './type'
import documentClient from '../../api/dynamoDb'
import { fetchBranches } from './branchesActions'

export const fetchHQ = (customerId, cb) => {

    return (dispatch, getState) => {
        let param = {
            TableName: "V1_HQ_HQ",
            Key: {
                uid: customerId,
            },
        };
        fetchGet(param, data => {

            dispatch({
                type: type.FETCH_HQ,
                payload: (data && data.Item) || {}
            });
            dispatch(fetchBranches())
            cb && cb();
        });
    };
};

const fetchGet = (params, cb) => {
    documentClient.get(params, (err, data) => {
        if (err) {
            console.log(err);
            // alert(err)
        } else {
            cb(data);
        }
    });
};

export const sortBranch = (branchesArray) => {
    return (dispatch, getState) => {
        const { HQ_Info, user } = getState()

        let params = {
            TableName: "V1_HQ_HQ",
            Key: {
                uid: HQ_Info.uid
            },
            UpdateExpression: "set branches = :newBranches",
            // ExpressionAttributeNames: { "#name": "name" },
            ExpressionAttributeValues: {
                ":newBranches": branchesArray
            }
        };
        documentClient.update(params, function (err, data) {
            if (err) {
                alert(err);
            } else {
                console.log('Sorting Complete !')
                dispatch(fetchHQ(user.loggedIn.customerId))
            }
        });
    }
}