import React, { Component } from "react";
import Navbar from "./navbar";
import LeftSideBar from "./leftSideBar";
import MainContent from "./MainContent";
// import { withRouter } from "react-router-dom";
// import _ from "lodash";
// import Footer from "./Footer";

class Container extends Component {
  state = {
    enlarged: true,
    activeLink: "liveScore"
  };

  setActiveLink = activeLink => {
    this.setState({
      activeLink
    });
    setTimeout(this.checkTitleSize, 100);
  };

  checkTitleSize = () => {
    var h = window.$("#shop-name").height();

    if (h > 80) {
      window.$("#shop-name").css("font-size", "0.8em");
    } else {
      window.$("#shop-name").css("font-size", "20px");
    }
  };

  componentDidMount() {}

  setEnlarged = (e, forceClose) => {
    let class1 = window.$("#root").attr("class");
    let cssClass = "fixed-left";
    if (forceClose || class1 === "fixed-left") {
      cssClass = "fixed-left enlarged";
    } else {
    }
    window.$("#root").removeClass();
    window.$("#root").addClass(cssClass);
  };

  render() {
    const { activeLink } = this.state;
    const { history } = this.props;

    return (
      <div id="wrapper">
        <Navbar
          setEnlarged={this.setEnlarged}
          title={activeLink}
          history={history}
        />
        <LeftSideBar
          history={this.props.history}
          activeLink={activeLink}
          setActiveLink={this.setActiveLink}
          setEnlarged={() => {
            let screenWidth = window.$(window).width();
            if (screenWidth > 768) return;
            this.setEnlarged();
          }}
        />

        <div className="content-page" onClick={e => this.setEnlarged(e, true)}>
          <div className="content">
            {/* {this.state.barrier && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "fixed",
                  // position: "relative",
                  backgroundColor: "yellow",
                  zIndex: 999
                }}
                onClick={e => this.setEnlarged(e, true)}
              />
            )} */}
            <div className="container-fluid">
              <MainContent />
            </div>
          </div>

          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}

export default Container;
