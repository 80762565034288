import * as type from '../actions/type'

const reducer = (state = { initial: true, custom: false }, action) => {
    switch (action.type) {
        case type.SET_INITIAL_LOADING:
            return { ...state, initial: action.payload }
        case type.SET_CUSTOM_LOADING:
            return { ...state, custom: action.payload }
        default:
            return state
    }
}

export default reducer