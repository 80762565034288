import Amplify, { Auth } from "aws-amplify";
import { cognitoidentityserviceprovider } from "./dynamoDb";
import swal from "sweetalert";

const userPoolId = "ap-southeast-1_ac1LQwEMu";
Amplify.configure({
  Auth: {
    region: "ap-southeast-1",
    userPoolId,
    userPoolWebClientId: "1uc84e45crb6cq09ang3mr18ag"
  }
});
export const logIn = (username, password, cbSuccess) => {
    return Auth.signIn(username, password)
        .then(user => {
            // console.log(user)
            if (cbSuccess) {
                cbSuccess()
                return
            }
            window.location.href = "/"
        })
        .catch(err => {
            swal("เกิดข้อผิดพลาด", err.message, "error");
        });
}
// export const signUp = (user, cbSuccess, cbError, isAddToBranch) => { //test
export const signUp = (user, cbSuccess) => {
    Auth.signUp({
        username: user.username,
        password: user.password,
        attributes: {
            email: user.username + '@niceloop.com',// optional
            // other custom attributes 
        },
        // validationData: []  //optional
    })
        .then(data => {
            console.log(data);
            var params = {
                UserPoolId: userPoolId, /* required */
                Username: user.username /* required */
            };

            cognitoidentityserviceprovider.adminConfirmSignUp(params, function (err, data) {
                if (err) {
                    console.log(err, err.stack);
                    cbSuccess && cbSuccess(err)
                } // an error occurred
                else {     // successful response
                    console.log(data);
                    cbSuccess && cbSuccess()

                }
            });
        })
        .catch(err => {

            console.log(err)
            // cbError(err)
            if (err.code === "UsernameExistsException") {
                swal("เกิดข้อผิดพลาด", "Username นี้ถูกใช้แล้ว", "error");
                return
            }
            if (err.code === 'InvalidParameterException') {
                swal("เกิดข้อผิดพลาด", "Invalid E-mail format", "error");
                return
            }
            swal("เกิดข้อผิดพลาด", err.code, "error");

        });
}

