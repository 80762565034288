import React from 'react';
import moment from 'moment'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as firebaseRef from '../../shared/firebaseApi/firebaseRef'

class LiveTableDetail extends React.Component {
    state = {
        table: {}
    }

    componentDidMount() {
        this.getTableDetail()
    }

    getTableDetail = () => {
        const { match, history } = this.props
        const { customerId, tableNo } = match.params

        firebaseRef.customersRef.child(customerId).child('table_v2').child(tableNo)
            .on('value', (snapshot) => {
                if (!snapshot) {
                    history.push('/liveScore')
                    return
                }
                let table = snapshot.val();

                this.setState({
                    table
                })
            })

    }

    componentWillUnmount() {
        const { match } = this.props
        const { customerId, tableNo } = match.params

        firebaseRef.customersRef.child(customerId).child('table_v2').child(tableNo).off()

    }

    render() {
        const { match, branches, history } = this.props
        const { table } = this.state
        const branchName = branches[match.params.customerId].Value
        return (
            <div>
                <center><h4>{branchName} - {table.shortAlias || ''}</h4></center>
                <div className="card">
                    <div className="card-body">
                        <div></div>
                        <HeadDetailLine left={'Time:'} right={`${moment(table.startTime).format('HH:mm DD/MMM/YYYY')}`} />
                        <HeadDetailLine left={'Table:'} right={<span>{`${table.shortAlias} (Live)`}</span>} />
                    </div>
                </div>
                <ul class="list-group">
                    {
                        _.map(table.lines, (data, index) => {
                            return (
                                <li class="list-group-item"
                                    // onClick={() => history.push(`${match.url}/liveTable/${customerId}`)}
                                    style={{ backgroundColor: index % 2 === 0 ? '#e6ecf7' : 'white', padding: 10 }}
                                >
                                    <div class=" d-flex justify-content-between align-items-center" >
                                        <span><span style={{ fontWeight: 'bold', color: 'blue' }}>{data.quantity}</span> {data.name}</span>
                                        <span><span style={{ fontWeight: 'bold' }}>฿ {data.discountedPrice.toLocaleString()}</span></span>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
                <div className="card" style={{ marginTop: 20 }}>
                    <div className="card-body">
                        <div></div>
                        <HeadDetailLine left={'SubTotal:'} right={(table.subTotal || 0).toLocaleString()} />
                        <HeadDetailLine left={'Discount Items:'} right={<span>{`${((table.discount && table.discount.amount) || 0).toLocaleString()}`}</span>} />
                        <HeadDetailLine left={'ServiceCharge:'} right={<span>{`${((table.service && table.service.amount) || 0).toLocaleString()}`}</span>} />
                        <HeadDetailLine left={<span style={{ fontWeight: 'bold' }}>GrandTotal:</span>} right={<span style={{ fontWeight: 'bold' }}>{`${(table.grandTotal || 0).toLocaleString()}`}</span>} />
                    </div>
                </div>
                <button class="btn btn-primary w-md waves-effect waves-light" style={{ width: '100%' }} onClick={() => history.push('/')}>Back</button>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        branches: state.branches
    }
}

export default connect(mapStateToProps)(LiveTableDetail)

const HeadDetailLine = ({ left, right }) => {
    return (
        <div className="row m-0" style={{ marginTop: 5 }}>
            <div className="col-6">{left}</div>
            <div className="col-6">{right}</div>
        </div>
    )
}