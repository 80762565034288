import React from "react";
import _ from "lodash";
import moment from "moment";
import documentClient from "../../shared/api/dynamoDb";
import { withRouter } from "react-router-dom";
import SummaryCard from "../../shared/components/SummaryCard";
import CustomLoading from "../../shared/components/CustomLoading";
import MediaQuery from "react-responsive";

class SalesSummary extends React.Component {
  state = {
    month: moment().format("YYYY-MM"),
    summary: [],
    sumGrandTotal: 0,
    sumBills: 0,
    sumGuest: 0,

    loading: true
  };

  componentDidMount() {
    this.fetch(this.state.month);
  }

  componentWillReceiveProps(nextProps) {
    this.fetch(this.state.month, nextProps.match.params.customerId);
  }
  getDateListDesc = month => {
    const startOfMonth = moment(month).startOf("month");
    const endOfMonth = moment(month).endOf("month");

    let diff = endOfMonth.diff(startOfMonth, "days") + 1;
    if (month === moment().format("YYYY-MM")) {
      //ถ้าเดือนปัจจุบัน เอาแค่ถึงวันที่ปัจจุบัน
      diff = moment().diff(startOfMonth, "days") + 1;
    }

    let datesOfMonth = [];

    _.times(diff, index => {
      datesOfMonth.push(
        moment(month)
          .startOf("month")
          .add(index, "days")
          .format("YYYY-MM-DD")
      );
    });
    return datesOfMonth;
  };
  fetch = (month, forceCustomerId) => {
    //props ส่งมาไม่ทันใช้ Force

    const { customerId } = this.props;
    this.setState({
      loading: true
    });
    let params = {
      TableName: "OrderSummary",
      KeyConditionExpression:
        "#customerId = :customerId and begins_with(#time, :time)",
      ExpressionAttributeNames: {
        "#time": "time",
        "#customerId": "customerId"
      },
      ExpressionAttributeValues: {
        // ":customerId": "3635",
        ":customerId": forceCustomerId || customerId,
        ":time": month
        // ":time": "2018-11"
      }
    };
    const self = this;

    let sumGrandTotal = 0;
    let sumBills = 0;
    let sumGuest = 0;
    let summary = {};

    documentClient.query(params, function(err, data) {
      if (err) {
        console.log(err);
        this.setState({
          loading: true
        });
      } else {
        let datesArr = self.getDateListDesc(month);
        let datesObj = {};

        _.forEach(datesArr, date => {
          //make 30 days
          datesObj[date] = {
            time: date
          };
        });

        _.forEach(data.Items, i => {
          i["avgBills"] = i.grandTotal / i.bill;
          i["avgGuest"] = i.grandTotal / i.guest;
          // summary.push(i)
          summary[i.time] = i;
          sumGrandTotal += i.grandTotal;
          sumBills += i.bill;
          sumGuest += i.guest;
        });

        summary = { ...datesObj, ...summary }; //put exist data and blank data
        summary = _.orderBy(summary, ["time"], ["desc"]);

        self.setState({
          summary,
          sumGrandTotal,
          sumBills,
          sumGuest,
          loading: false
        });
      }
    });
  };

  getAvgValue = value => {
    let computed =
      parseInt(
        (this.state.sumGrandTotal / value).toFixed(0)
      ).toLocaleString() || 0;
    if (computed.toString() === "NaN") {
      computed = 0;
    }
    return computed;
  };

  convertNumber = number => {
    return parseInt((number || 0).toFixed(0)).toLocaleString();
  };

  checkWeek = date => {
    let border = false;
    let date2 = moment(date).format("DD/MM/YYYY ddd");
    let startLength = date2.length - 3;
    let dateName = date2.slice(startLength, date2.length);

    if (dateName === "Sun") {
      border = true;
    }

    return border;
  };

  getMonthPicker = mode => {
    const { month } = this.state;
    return (
      <div>
        <small style={{ color: "grey" }}>Select Month</small>
        <input
          className="form-control"
          type="month"
          value={month}
          onChange={e => this.handleSetMonth(e)}
        />
      </div>
    );
  };

  render() {
    const { summary, sumGrandTotal, sumBills, sumGuest, loading } = this.state;
    const avgBills = this.getAvgValue(sumBills);
    // const avgGuest = this.getAvgValue(sumGuest)
    let listGroupBGColor = "#e6ecf7";
    if (loading) {
      return <CustomLoading loading={loading} />;
    }
    return (
      <div>
        <MediaQuery maxDeviceWidth={500}>
          {this.getMonthPicker()}
          <SummaryCard
            title={sumGrandTotal.toLocaleString()}
            subtitle="Grand Total"
            marginLeft="0"
            marginTop={20}
          />

          <ul class="list-group" >
            {_.map(summary, (i, customerId) => {
              if (this.checkWeek(i.time)) {
                if (listGroupBGColor === "#e6ecf7") {
                  listGroupBGColor = "white";
                } else {
                  listGroupBGColor = "#e6ecf7";
                }
              }
              return (
                <li
                  key={customerId}
                  class="list-group-item d-flex justify-content-between align-items-center"
                  style={{ backgroundColor: listGroupBGColor }}
                >
                  {moment(i.time).format("DD MMMM YYYY ddd")}
                  <span style={{ color: "#e2595f", fontSize: 18 }}>
                    {this.convertNumber(i.grandTotal)}฿
                  </span>
                </li>
              );
            })}
          </ul>
        </MediaQuery>

        <MediaQuery minDeviceWidth={501}>
          <div className="row " style={{ margin: 0 }}>
            <div className="col-md-10 offset-md-1">
              <div className="row " style={{ margin: 0, minHeight: 100 }}>
                <div className="align-self-center" style={{ paddingLeft: 0 }}>
                  {this.getMonthPicker()}
                </div>
                <SummaryCard
                  title={sumGrandTotal.toLocaleString()}
                  subtitle="Grand Total"
                />
                <SummaryCard
                  title={`Avg ${avgBills}/Bill`}
                  subtitle={`${sumBills.toLocaleString()} Bills`}
                />
                {/* <SummaryCard title={`${avgGuest}/Head`} subtitle={`${sumGuest.toLocaleString()} Customers`} /> */}
              </div>
              {summary.length > 0 && (
                <div className="card" style={{ marginTop: 10 }}>
                  <div className="card-body">
                    <h3 className="mt-0 header-title">ภาพรวมสาขา</h3>
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead className="thead-default">
                          <tr>
                            <th>Date</th>
                            <th style={{ textAlign: "right" }}>Grand Total</th>
                            <th style={{ textAlign: "right" }}>Bills</th>
                            <th style={{ textAlign: "right" }}>AvgBills</th>
                            {/* <th style={{ textAlign: 'right' }}>Guests</th> */}
                            {/* <th style={{ textAlign: 'right' }}>AvgGuests</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {_.map(summary, (i, index) => {
                            // let avgGuest = (i.avgGuest !== Infinity && i.avgGuest) || 0
                            let avgBills =
                              (i.avgBills !== Infinity && i.avgBills) || 0;
                            return (
                              <tr
                                style={
                                  this.checkWeek(i.time)
                                    ? {
                                        borderTop: "solid",
                                        borderTopColor: "lightgrey",
                                        borderWidth: 3
                                      }
                                    : {}
                                }
                              >
                                <th scope="row">
                                  {moment(i.time).format("DD MMMM YYYY ddd")}
                                </th>
                                <td style={{ textAlign: "right" }}>
                                  <span
                                    style={{
                                      color: "blue",
                                      fontWeight: "bold"
                                    }}
                                  >
                                    {this.convertNumber(i.grandTotal)}
                                  </span>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  {this.convertNumber(i.bill)}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  {this.convertNumber(avgBills)}
                                </td>
                                {/* <td style={{ textAlign: 'right' }}>{this.convertNumber(i.guest)}</td> */}
                                {/* <td style={{ textAlign: 'right' }}>{this.convertNumber(avgGuest)}</td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
  handleSetMonth = e => {
    this.fetch(e.target.value);
    this.setState({ month: e.target.value });
  };
}

export default withRouter(SalesSummary);
