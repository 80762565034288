import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle
} from "react-sortable-hoc";
import * as hqInfoActions from "../shared/redux/actions/hqInfoActions";
import { bindActionCreators } from "redux";
import { Auth } from "aws-amplify";
import MediaQuery from "react-responsive";

// const DragHandle = SortableHandle(() => <i className="mdi mdi-source-branch" />); // This can be any component you want

class LeftSideBar extends Component {
  signOut = e => {
    e.preventDefault();
    if (window.confirm("Confirm Logout ?")) {
      Auth.signOut();
      window.location.href = "/";
    }
  };

  render() {
    const {
      branches,
      activeLink,
      setActiveLink,
      actions,
      hqInfo,
      loggedInUser,
      setEnlarged
    } = this.props;

    return (
      <div className="left side-menu">
        <div
          className="slimScrollDiv active"
          style={{
            position: "relative",
            overflow: "hidden",
            width: "auto",
            height: "100%"
          }}
        >
          <div
            className="slimscroll-menu in"
            id="remove-scroll"
            style={{
              overflow: "hidden",
              width: "auto",
              height: "100%",
              overflowY: "scroll"
            }}
          >
            {/* <!--- Sidemenu --> */}
            <div id="sidebar-menu" className="active">
              {/* <!-- Left Menu Start --> */}
              <ul className="metismenu in" id="side-menu">
                <li className="menu-title">Main</li>
                <li>
                  <Link
                    to="/"
                    onClick={() => {
                      setActiveLink("liveScore");
                      setEnlarged();
                    }}
                    className={`waves-effect ${
                      activeLink === "liveScore" ? "active" : ""
                    }`}
                  >
                    <i className="mdi mdi-theater" />
                    <span>Live Score</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/dashboard"
                    onClick={() => {
                      setActiveLink("dashboard");
                      setEnlarged();
                    }}
                    className={`waves-effect ${
                      activeLink === "dashboard" ? "active" : ""
                    }`}
                  >
                    <i className="dripicons-meter" />
                    <span>Dashboard</span>
                  </Link>
                </li>
              </ul>

              <SortableComponent
                handleClick={setActiveLink}
                branches={branches}
                hqInfo={hqInfo}
                activeLink={activeLink}
                actions={actions}
                setEnlarged={setEnlarged}
              />

              <ul className="metismenu in" id="side-menu">
                <li className="menu-title">Settings</li>
                {loggedInUser.role === "admin" && [
                  <li>
                    <Link
                      to="/manageUser"
                      onClick={() => {
                        setActiveLink("manageUser");
                        setEnlarged();
                      }}
                      className={`waves-effect ${
                        activeLink === "manageUser" ? "active" : ""
                      }`}
                    >
                      <i className="mdi mdi-account" />
                      <span>Manage User (for HQ)</span>
                    </Link>
                  </li>,
                  <MediaQuery maxDeviceWidth={500}>
                    <li>
                      <a className={`waves-effect`} onClick={this.signOut}>
                        <i className="mdi mdi-logout" />
                        <span>Logout</span>
                      </a>
                    </li>
                  </MediaQuery>
                ]}

                <li>
                  <Link
                    to="/resetPassword"
                    onClick={() => {
                      setActiveLink("resetPassword");
                      setEnlarged();
                    }}
                    className={`waves-effect ${
                      activeLink === "resetPassword" ? "active" : ""
                    }`}
                  >
                    <i className="mdi mdi-account" />
                    <span>Reset Password</span>
                  </Link>
                </li>
                {/* history.push('/resetPassword') */}
              </ul>
            </div>
            {/* <!-- Sidebar --> */}
            <div className="clearfix" />
          </div>
          <div className="slimScrollBar" />
          <div className="slimScrollRail" />
        </div>
        {/* <!-- Sidebar -left --> */}
      </div>
    );
  }
}

class SortableComponent extends Component {
  state = {
    items: [],
    timestamp: 1
  };
  componentWillReceiveProps(nextProps) {
    let { timestamp, hqInfo } = nextProps;
    // if (timestamp !== this.state.timestamp) {
    this.setState({ items: hqInfo.branches, timestamp });
    // }
  }

  componentDidMount() {
    let { timestamp, hqInfo } = this.props;
    this.setState({ items: hqInfo.branches, timestamp });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let { actions } = this.props;
    let newItems = arrayMove(this.state.items, oldIndex, newIndex);
    this.setState({
      items: newItems
    });
    actions.sortBranch(newItems);
  };
  render() {
    let { handleClick, activeLink, branches, setEnlarged } = this.props;
    if (_.isEmpty(branches)) return <div />;
    return (
      <SortableList
        // useDragHandle={true}
        handleClick={handleClick}
        items={this.state.items}
        branches={branches}
        onSortEnd={this.onSortEnd}
        activeLink={activeLink}
        pressDelay={2000}
        helperClass={"yellowBG"}
        setEnlarged={setEnlarged}
      />
    );
  }
}

const SortableList = SortableContainer(
  ({ items, handleClick, activeLink, branches, setEnlarged }) => {
    return (
      <ul className="metismenu in" id="side-menu">
        <li className="menu-title">Branches</li>
        {_.map(items, (value, index) => {
          // debugger
          if (value === 9999) {
            return (
              <li>
                <a className="waves-effect">==== Master Franchise ====</a>
              </li>
            );
          }
          if (value === 8888) {
            return (
              <li>
                <a className="waves-effect">==== Franchisee ====</a>
              </li>
            );
          }
          if (value === 7777) {
            return (
              <li>
                <a className="waves-effect">==== Other ====</a>
              </li>
            );
          }
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={value}
              handleClick={handleClick}
              activeLink={activeLink}
              branches={branches}
              // distance={2000}
              helperClass={{ visibility: "visible" }}
              setEnlarged={setEnlarged}
            />
          );
        })}
      </ul>
    );
  }
);

const SortableItem = SortableElement(
  ({ value, handleClick, activeLink, branches, setEnlarged }) => (
    <li style={{ visibility: "visible", zIndex: 999999, opacity: 100 }}>
      <Link
        // style={{ visibility: 'visible', backgroundColor: 'transparent' }}
        onClick={() => {
          handleClick(value);
          setEnlarged();
        }}
        to={`/branch/${value}`}
        className={`waves-effect ${
          activeLink.toString() === value.toString() ? "active" : ""
        }`}
      >
        <i className="mdi mdi-source-branch" />
        {/* <DragHandle /> */}
        <span>{branches[value].Value}</span>
      </Link>
    </li>
  )
);

const mapStateToProps = state => {
  return {
    hqInfo: state.HQ_Info,
    branches: state.branches,
    loggedInUser: state.user.loggedIn
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...hqInfoActions }, dispatch);
  return {
    actions
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LeftSideBar)
);
