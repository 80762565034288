import React from 'react';
import moment from 'moment'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from 'sweetalert'
import * as firebaseRef from '../../shared/firebaseApi/firebaseRef'

class LiveTable extends React.Component {
    state = {
        tables: [],
        totalPaid: 0,
        totalPending: 0
    }

    componentDidMount() {
        this.getTableData()
        this.getTotalData()

    }

    // componentWillUpdate() {
    //     this.getTableData()
    //     this.getTotalData()
    // }

    getTotalData = () => {
        const { match } = this.props
        const customerId = match.params.customerId

        firebaseRef.liveRef.child(customerId)
            .on('value', (snapshot) => {
                if (!snapshot) return
                let data = snapshot.val();
                let totalPending = data.pending || 0;
                let totalPaid = (data.payment && data.payment.amount) || 0

                this.setState({
                    totalPending,
                    totalPaid
                })
            })

    }

    getTableData = () => {
        const { match } = this.props
        const customerId = match.params.customerId
        firebaseRef.customersRef.child(customerId).child('table_v2')
            .on('value', (snapshot) => {
                if (!snapshot) return
                let tables = []

                let tablesObj = snapshot.val();
                _.map(tablesObj, (data, key) => {
                    if (_.isEmpty(data)) return
                    if (data.grandTotal < 1) return
                    tables.push({ ...data, key })
                })
                tables = _.orderBy(tables, ['key'], ['asc'])
                // tables = _.sortBy(tables, i => i.shortAlias)
                this.setState({
                    tables
                })
            })
    }

    componentWillUnmount() {
        const { match } = this.props
        const customerId = match.params.customerId
        firebaseRef.customersRef.child(customerId).child('table_v2').off()
        firebaseRef.liveRef.child(customerId).off()
    }

    render() {
        const { match, branches, history } = this.props
        const { tables, totalPaid, totalPending } = this.state
        const branchName = branches[match.params.customerId].Value
        return (
            <div>
                <center><h4>{branchName}</h4></center>
                <div className="card">
                    <div className="card-body">
                        <HeadDetailLine left={'Active:'} right={`${tables.length} Tables`} />
                        <HeadDetailLine left={'Paid:'} right={<span style={{ color: 'blue' }}>{`฿ ${totalPaid.toLocaleString()}`}</span>} />
                        <HeadDetailLine left={'Pending (Tables):'} right={<span style={{ color: 'red' }}>{`฿ ${totalPending.toLocaleString()}`}</span>} />
                        <HeadDetailLine left={'Total:'} right={<span style={{ color: 'green' }}>{`฿ ${(totalPending + totalPaid).toLocaleString()}`}</span>} />
                    </div>
                </div>
                <ul class="list-group">
                    {
                        _.map(tables, (data, index) => {
                            return (
                                <li class="list-group-item"
                                    onClick={() => history.push(`/tableInfo/${match.params.customerId}/${data.key}`)}
                                    style={{ backgroundColor: index % 2 === 0 ? '#e6ecf7' : 'white', padding: 10 }}
                                >
                                    <div class=" d-flex justify-content-between align-items-center" >
                                        <span style={{ fontWeight: 'bold' }}><span style={{ color: 'grey', fontWeight: 'normal' }}>Table</span> {data.shortAlias}</span>
                                        <span><span style={{ fontWeight: 'bold', color: 'red' }}>฿ {data.grandTotal.toLocaleString()}</span> <i className="mdi mdi-arrow-right-drop-circle" style={{ fontSize: 20 }}></i></span>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
                <button class="btn btn-primary w-md waves-effect waves-light" style={{ width: '100%', marginTop: 10 }} onClick={() => history.push('/')}>Back</button>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        branches: state.branches
    }
}

export default connect(mapStateToProps)(LiveTable)

const HeadDetailLine = ({ left, right }) => {
    return (
        <div className="row m-0" style={{ marginTop: 5 }}>
            <div className="col-6">{left}</div>
            <div className="col-6"><span style={{ fontWeight: 'bold' }}>{right}</span></div>
        </div>
    )
}