import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import documentClient from "../../shared/api/dynamoDb";
import async from "async";
import _ from "lodash";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import SummaryCard from "../../shared/components/SummaryCard";
import MediaQuery from "react-responsive";
import DatePickerModal from "./DatePickerModal";

const dateModeStyle = { width: "35%" };

class Dashboard extends React.Component {
  state = {
    filterMode: "date", //month,year
    date: moment().format("YYYY-MM-DD"),
    dateObj: Date.now(),
    summary: {},
    resultDate: "",
    resultTimestamp: "",

    sumGrandTotal: 0,
    sumBills: 0,
    sumGuest: 0,

    showMonthCard: false
  };

  componentDidMount() {
    this.fetch(this.state.date);
  }
  dateObjToString = date => {
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    const dateString = `${date.getFullYear().toString()}-${month.toString()}-${
      date.getDate() < 10
        ? "0" + date.getDate().toString()
        : date.getDate().toString()
    }`;
    return dateString;
  };
  saveDates = dateObj => {
    const date = this.dateObjToString(dateObj);
    this.setState({
      dateObj,
      date
    });

    this.fetch(date);
  };

  render() {
    const {
      date,
      dateObj,
      filterMode,
      summary,
      resultDate,
      resultTimestamp,
      sumGrandTotal,
      sumBills,
      sumGuest,
      showMonthCard
    } = this.state;
    const avgBills = this.getAvgValue(sumBills);
    // const avgGuest = this.getAvgValue(sumGuest)
    const days = this.getCurrentMonthDays();
    let itemIndex = 0;
    return (
      <div className="page-title-box" style={{ minHeight: 500 }}>
        {/* MOBILE */}
        <MediaQuery maxDeviceWidth={500}>
          <DatePickerModal saveDates={this.saveDates} startDate={dateObj} />
          <div className="row justify-content-between m-0">
            <div>
              <h3>Dashboard</h3>
            </div>
            <div className="align-self-center">
              {this.getReloadButton("mobile")}
            </div>
          </div>
          <div className="row m-0">
            <div className="col-7 col-xs-6 m-0 p-0">
              {this.getTimePickers("mobile")}
            </div>
            <div className="col-5 col-xs-6 m-0 p-0" style={{ paddingLeft: 0 }}>
              {this.getFilterButtons()}
            </div>
          </div>
          <SummaryCard
            title={sumGrandTotal.toLocaleString()}
            subtitle="Grand Total"
            marginLeft="0"
            marginTop={20}
          />

          <ul className="list-group">
            {_.map(summary, (i, customerId) => {
              itemIndex++;
              if (i.customerId === 9999) {
                return <TopicHead text=" ==== Master Franchise ====" />;
              }
              if (i.customerId === 8888) {
                return <TopicHead text=" ==== Franchisee ====" />;
              }
              if (i.customerId === 7777) {
                return <TopicHead text=" ==== Other ====" />;
              }

              return (
                <li
                  key={customerId}
                  className="list-group-item"
                  style={{
                    backgroundColor: itemIndex % 2 === 0 ? "#e6ecf7" : "white"
                  }}
                >
                  <div className=" d-flex justify-content-between align-items-center">
                    {i.name}
                    <span style={{ color: "#e2595f", fontSize: 18 }}>
                      {this.convertNumber(i.grandTotal)}฿
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
          <div style={{ marginTop: 10 }} />
          <span>ค้นหาเมื่อ: {resultTimestamp}</span>
        </MediaQuery>
        {/* DESKTOP */}
        <MediaQuery minDeviceWidth={501}>
          <div className="row m-0" style={{ marginTop: 15 }}>
            <div className="col-md-10 offset-md-1">
              <div
                className="row"
                style={{ margin: 0, minHeight: 100, flex: 1 }}
              >
                <div className="align-self-center" style={{ paddingLeft: 0 }}>
                  <div className="row m-0">
                    {this.getFilterButtons()}
                    {this.getReloadButton()}
                  </div>
                  {this.getTimePickers()}
                </div>

                <SummaryCard
                  title={sumGrandTotal.toLocaleString()}
                  subtitle="Grand Total"
                />
                {/* <SummaryCard title={`${avgGuest}/Head`} subtitle={`${sumGuest.toLocaleString()} Customers`} /> */}
                {filterMode === "month" && showMonthCard && (
                  <SummaryCard
                    title={`${this.convertNumber(sumGrandTotal / days)}/Day`}
                    subtitle={`Avg per day`}
                  />
                )}
                <SummaryCard
                  title={`Avg ${avgBills}/Bill`}
                  subtitle={`${sumBills.toLocaleString()} Bills`}
                />
              </div>
              {!_.isEmpty(summary) && (
                <div>
                  <span style={{ fontSize: 15 }}>Result for: {resultDate}</span>
                  <div className="card" style={{ marginTop: 10 }}>
                    <div className="card-body">
                      <h3 className="mt-0 header-title">ภาพรวมสาขา</h3>
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <thead className="thead-default">
                            <tr>
                              <th>ชื่อ</th>
                              <th style={{ textAlign: "right" }}>ยอดขาย</th>
                              <th style={{ textAlign: "right" }}>บิล</th>
                              <th style={{ textAlign: "right" }}>Avg/bill</th>
                              {/* <th style={{ textAlign: 'right' }}>ลูกค้า</th> */}
                              {/* <th style={{ textAlign: 'right' }}>Avg/ลูกค้า</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(summary, (i, customerId) => {
                              if (i.customerId === 9999) {
                                return (
                                  <tr>
                                    <th scope="row">
                                      {" "}
                                      ==== Master Franchise ====
                                    </th>
                                  </tr>
                                );
                              }
                              if (i.customerId === 8888) {
                                return (
                                  <tr>
                                    <th scope="row"> ==== Franchisee ====</th>
                                  </tr>
                                );
                              }
                              if (i.customerId === 7777) {
                                return (
                                  <tr>
                                    <th scope="row"> ==== Other ====</th>
                                  </tr>
                                );
                              }
                              // let avgGuest = (i.avgGuest !== Infinity && i.avgGuest) || 0
                              let avgBills =
                                (i.avgBills !== Infinity && i.avgBills) || 0;

                              return (
                                <tr key={customerId}>
                                  <th scope="row">{i.name}</th>
                                  <td style={{ textAlign: "right" }}>
                                    <span
                                      style={{
                                        color: "blue",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {this.convertNumber(i.grandTotal)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {this.convertNumber(i.bills)}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {this.convertNumber(avgBills)}
                                  </td>
                                  {/* <td style={{ textAlign: 'right' }}>{this.convertNumber(i.guest)}</td> */}
                                  {/* <td style={{ textAlign: 'right' }}>{this.convertNumber(avgGuest)}</td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <span>ค้นหาเมื่อ: {resultTimestamp}</span>
                </div>
              )}
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
  convertNumber = number => {
    let res = parseInt(number.toFixed(0)).toLocaleString();
    return _.isNaN(res) ? 0 : res;
  };

  getCurrentMonthDays = () => {
    const { date } = this.state;
    let days = 0;
    let startOfMonth = moment(date).startOf("month");
    if (moment(date).format("YYYY-MM") !== moment().format("YYYY-MM")) {
      days =
        moment(date)
          .endOf("month")
          .diff(startOfMonth, "days") + 1;
    } else {
      days =
        moment(moment().format("YYYY-MM-DD")).diff(startOfMonth, "days") + 1;
    }
    return days;
  };

  getTimePickers = mode => {
    const { filterMode, date, dateObj } = this.state;
    return (
      <div style={mode === "mobile" ? {} : { marginTop: 6 }}>
        {filterMode === "date" && mode !== "mobile" && (
          <DatePicker
            className="form-control"
            selected={dateObj}
            onChange={this.setDate}
            dateFormat="dd/MM/YYYY"
            id="date-pick"
          />
        )}
        {filterMode === "date" && mode === "mobile" && (
          <div
            className={`input-group bootstrap-touchspin bootstrap-touchspin-injected`}
            onClick={() => window.$("#dateButton").click()}
          >
            <input
              type="text"
              value={`${moment(date).format("DD/MM/YYYY")}`}
              name="demo1"
              className="form-control"
              disabled
            />
            <button
              style={{ height: 38 }}
              id="dateButton"
              type="button"
              data-toggle="modal"
              data-target="#myModal"
              style={{ visibility: "hidden" }}
            />
          </div>
        )}
        {filterMode === "month" && (
          <input
            className="form-control"
            value={date}
            type="month"
            onChange={e => this.handleSetMonth(e)}
          />
        )}
        {filterMode === "year" && (
          <select
            className="form-control"
            onChange={e => {
              if (e.target.value === "") return;
              this.handleSetMonth(e);
            }}
          >
            <option value="">Select Year</option>
            <option value="2018">2018</option>
            <option value="2019">2019</option>
          </select>
        )}
      </div>
    );
  };

  getFilterButtons = () => {
    const { filterMode } = this.state;
    return (
      <div
        className="btn-group btn-group-toggle"
        data-toggle="buttons"
        style={{ height: 38 }}
      >
        <ModeButton
          mode="date"
          filterMode={filterMode}
          onClick={this.changeDateMode}
        />
        <ModeButton
          mode="month"
          filterMode={filterMode}
          onClick={this.changeDateMode}
        />
        <ModeButton
          mode="year"
          filterMode={filterMode}
          onClick={this.changeDateMode}
        />
      </div>
    );
  };

  getReloadButton = mode => {
    const { date } = this.state;
    if (mode === "mobile") {
      return (
        <a
          className="waves-effect waves-light"
          href="#"
          style={{ fontSize: 20, paddingLeft: 10 }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            this.fetch(date);
          }}
          role="button"
        >
          <i className="mdi mdi-reload" />
          Reload
        </a>
      );
    }
    return (
      <div style={{ marginLeft: 5 }}>
        <a
          className="btn btn-info waves-effect waves-light"
          href="#"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            this.fetch(date);
          }}
          role="button"
        >
          <i className="mdi mdi-reload" />
        </a>
      </div>
    );
  };
  getAvgValue = value => {
    let computed =
      parseInt(
        (this.state.sumGrandTotal / value).toFixed(0)
      ).toLocaleString() || 0;
    if (computed.toString() === "NaN") {
      computed = 0;
    }
    return computed;
  };

  fetch = date => {
    const { branches, hqInfo } = this.props;

    let raw = {};
    const branchName = {};
    let sumGrandTotal = 0;
    let sumBills = 0;
    let sumGuest = 0;

    async.each(
      branches,
      (branch, cb) => {
        const customerId = branch.CustomerId.toString();
        branchName[customerId] = branch.Value;

        let params = {
          TableName: "OrderSummary",
          // Index: "time-customerId-index",
          KeyConditionExpression:
            "#customerId = :customerId and begins_with(#time, :time)",
          // KeyConditionExpression: '#customerId = :customerId and #time = :time',
          // KeyConditionExpression: "customerId = :customerId ",
          ExpressionAttributeNames: {
            "#time": "time",
            "#customerId": "customerId"
          },
          ExpressionAttributeValues: {
            // ":customerId": "3635",
            ":customerId": customerId,
            ":time": date
            // ":time": "2018-11"
          }
        };
        // "4274","4829","3049","4639","4912"
        documentClient.query(params, function(err, data) {
          if (err) {
            console.log(err);
            // cb(err)
            raw[branch.CustomerId] = [];
            cb();
          } else {
            raw[branch.CustomerId] = data.Items;
            cb();
            // return data
          }
        });
      },
      (err, res) => {
        if (err) {
          swal("เกิดข้อผิดพลาด", err, "error");
        } else {
          let summary = {};

          _.map(raw, (data, customerId) => {
            summary[customerId] = {
              name: branchName[customerId],
              grandTotal: 0,
              bills: 0,
              avgBills: 0,
              guest: 0,
              avgGuest: 0,
              customerId
            };
            _.forEach(data, i => {
              summary[customerId].grandTotal += i.grandTotal || 0;
              summary[customerId].bills += i.bill || 0;
              summary[customerId].guest += i.guest || 0;

              //CARD
              sumGrandTotal += i.grandTotal || 0;
              sumBills += i.bill || 0;
              sumGuest += i.guest || 0;
            });
            summary[customerId].avgBills =
              summary[customerId].grandTotal / summary[customerId].bills;
            summary[customerId].avgGuest =
              summary[customerId].grandTotal / summary[customerId].guest;
          });
          // summary = _.sortBy(summary, i => i.name.toUpperCase());
          let resultTimestamp = moment().toISOString();

          //SORT
          // hqInfo.branches
          let arrBranchRow = [];
          _.map(hqInfo.branches, customerId => {
            arrBranchRow.push(summary[customerId] || { customerId });
          });

          // debugger;

          this.setState({
            summary: arrBranchRow,
            resultDate: this.getResultDate(date),
            resultTimestamp,
            sumGrandTotal,
            sumBills,
            sumGuest
          });
          // done()
        }
      }
    );
    // fetchSales(hqInfo.branches || [])
  };

  getResultDate = date => {
    const { filterMode } = this.state;
    let time = "";
    switch (filterMode) {
      case "date":
        time = moment(date).format("DD MMM YYYY");
        break;
      case "month":
        time = moment(date).format("MMM YYYY");
        break;
      case "year":
        time = moment(date).format("YYYY");
        break;
    }
    return time;
  };

  setDate = dateObj => {
    const date = this.dateObjToString(dateObj);

    this.fetch(date);
    this.setState({
      date,
      dateObj
    });
  };

  changeDateMode = mode => {
    this.setState({
      filterMode: mode,
      // date: moment().format('dd/mm/yyyy'),
      showMonthCard: false,
      dateObj: Date.now()
    });
  };
  handleSetMonth = e => {
    this.fetch(e.target.value);
    this.setState({ date: e.target.value, showMonthCard: true });
  };
}

const mapStateToProps = state => {
  return {
    hqInfo: state.HQ_Info,
    branches: state.branches
  };
};

export default connect(mapStateToProps)(Dashboard);

const ModeButton = ({ mode, onClick, filterMode }) => {
  return (
    <label
      className={"btn btn-primary " + (filterMode === mode ? "active" : "")}
      style={dateModeStyle}
      onClick={() => onClick(mode)}
    >
      <input type="radio" name="options" />
      {getModeName(mode)}
    </label>
  );
};

const getModeName = mode => {
  let name = "วัน";
  switch (mode) {
    case "month":
      name = "เดือน";
      break;
    case "year":
      name = "ปี";
      break;
  }

  return name;
};

const TopicHead = ({ text }) => {
  return (
    <li
      className="list-group-item  mt-3"
      // style={{
      //   backgroundColor: "#abc8f7"
      // }}
    >
      <div className=" d-flex justify-content-between align-items-center">
        {text}
      </div>
    </li>
  );
};
