import React from 'react';
import { DateRangePicker, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import MediaQuery from 'react-responsive';

class DateRangePickerModal extends React.Component {

    state = {
        startDate: new Date(),
        endDate: new Date(),
    }

    pickDates = (ranges) => {
        const { startDate, endDate } = ranges.selection
        
        this.setState({
            startDate,
            endDate,
        })

    }

    render() {
        const { startDate, endDate } = this.state
        const { saveDates } = this.props

        const selectionRange = {
            startDate,
            endDate,
            key: 'selection',
        }

        return (
            <div class="modal fade bs-example-modal-center" tabindex="-1" role="dialog"
                aria-labelledby="mySmallModalLabel" id="myModal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Pick Date Range</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">x</button>
                        </div>
                        {/* <div class="modal-body">
                        </div> */}
                        <MediaQuery maxDeviceWidth={500}>
                            <DateRange
                                onChange={this.pickDates}
                                moveRangeOnFirstSelection={false}
                                ranges={[selectionRange]}
                                className={'PreviewArea'}
                            />
                        </MediaQuery>
                        <MediaQuery minDeviceWidth={501}>
                            <DateRangePicker
                                ranges={[selectionRange]}
                                onChange={this.pickDates}
                            />
                        </MediaQuery>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-success"
                                onClick={() => {
                                    saveDates(startDate, endDate)
                                    window.$('#myModal').modal('toggle');
                                }}
                            // data-dismiss="modal"
                            >Okay</button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default (DateRangePickerModal)